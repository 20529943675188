<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:45:07
-->
<template>
	<div class="recruitAdd">
		<div class="common-row">
			<div class="common-row-item">
				<span class="common-row-label">活动名称</span>
				<el-select @change="activityChange" class="common-row-select" v-model="recruitForm.activityId"
					placeholder="请选择">
					<el-option v-for="item in activityList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="common-row-tip">
				注：请将此活动招募关联至活动策划（需为审核通过/工时表等待提交状态），一旦创建无法修改。
			</div>
		</div>
		<div class="common-row">
			<div class="common-row-item">
				<span class="common-row-label">招募名称</span>
				<el-input style="flex: 1;" class="common-row-ipt" placeholder="请输入" v-model="recruitForm.recruitName" />
			</div>
		</div>
		<div class="common-row">
			<div class="common-row-item">
				<span class="common-row-label">招募校区</span>
				<div class="common-row-tablist">
					<span @click="tabSelect('school', item)" v-for="(item, index) in schoolList" :key="index"
						:class="{'tabitem_active': recruitForm.ccampus.includes(item)}"
						class="common-row-tabitem">{{item}}</span>
				</div>
			</div>
		</div>
		<div class="common-row">
			<div class="common-row-item">
				<span class="common-row-label">需求人数</span>
				<el-input style="flex: 1;" class="common-row-ipt" placeholder="请输入" v-model="recruitForm.peopleNum" />
			</div>
		</div>
		<div class="recruitAdd-desc">
			<div class="recruitAdd-desc-label">活动详情</div>
			<quill-editor :options="editorOption" class="common-table-val" :style="{'height': '320px'}"
				v-model="recruitForm.activityDetails" @focus="editorFocus($event)">
			</quill-editor>
			<!-- <el-input type="textarea" class="recruitAdd-desc-ipt" placeholder="请输入"
				v-model="recruitForm.activityDetails" /> -->
		</div>
		<div v-if="recruitForm.recruitTimes.length > 0&&recruitForm.activityId" class="">
			<div class="" v-for="(item, index) in recruitForm.recruitTimes" :key="index">
				<div class="common-row">
					<div class="common-row-item">
						<div class="common-row-content">
							<span class="common-row-label">活动时间</span>
							<div style="flex: 1;display: flex;align-items: center;">
								<el-date-picker :picker-options="pickerOptionsAc" style="flex: 1;"
								disabled
									format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" class="common-row-date"
									v-model="item.starTime" type="datetime" placeholder="请选择">
								</el-date-picker>
								<span style="margin: 0 10px;">-</span>
								<el-date-picker :picker-options="pickerOptionsAc" style="flex: 1;"
								disabled
									format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" class="common-row-date"
									v-model="item.endTime" type="datetime" placeholder="请选择">
								</el-date-picker>
							</div>
						</div>
						<!-- <div v-if="index == 0" @click="timeHandle('add')" class="common-row-icon">
							<img src="@/assets/images/common/add.png" alt="">
							<span>添加时间</span>
						</div>
						<div v-if="index > 0" @click="timeHandle('cut', index)" class="common-row-icon">
							<img src="@/assets/images/common/cut.png" alt="">
							<span>删除时间</span>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<div v-if="recruitForm.activityId" class="common-row">
			<div class="common-row-item">
				<span class="common-row-label">活动地址</span>
				<el-input placeholder="请输入活动地址" v-model="recruitForm.recruitAddress" class="common-row-ipt"
					style="flex: 1;" />
			</div>
		</div>
		<div class="common-row">
			<div class="common-row-item">
				<span class="common-row-label">定时招募</span>
				<el-date-picker @focus="hiddenNow" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm" class="common-row-date"
					v-model="recruitForm.timingRecruitTime" type="datetime" placeholder="请选择">
				</el-date-picker>
			</div>
		</div>
		<div class="common-row">
			<div class="common-row-item">
				<span class="common-row-label">定向招募</span>
				<div class="common-row-tablist">
					<span @click="tabSelect('deriction', item)" v-for="(item, index) in derictionList" :key="index"
						:class="{'tabitem_active': recruitForm.orientationId.includes(item.id)}"
						class="common-row-tabitem">{{item.name}}</span>
				</div>
			</div>
		</div>
		<div class="common-row">
			<div class="common-row-item">
				<span class="common-row-label">联系人</span>
				<el-input style="flex: 1;" class="common-row-ipt" placeholder="请输入活动报名联系人及联系方式"
					v-model="recruitForm.contact" />
			</div>
			<div class="common-row-tip">
				注：点击发布招募后优先给已参加过志愿服务的学生发送招募通知，然后在给未参加过志愿的学生发送招募通知，按照1000人/分钟进行发送。
			</div>
		</div>
		<div class="recruitAdd-submit">
			<el-button v-preventReClick @click="submitRecruit" class="recruitAdd-submit-btn" type="primary">修改招募
			</el-button>
		</div>
	</div>
</template>

<script>
	import {
		recruitFacultyList,
		detailRecruit,
		editRecruit,
	} from "@/api/recruit";
	import {
		getActivityList,
		getActivityDetail,
	} from "@/api/activity";
	export default {
		data() {
			return {
				recruitForm: {
					recruitName: "",
					activityId: "",
					activityName: "",
					ccampus: ["屏峰校区"],
					peopleNum: "",
					activityDetails: "",
					timingRecruitTime: "",
					orientationId: [],
					contact: "",
					recruitTimes: [],
					recruitAddress: ""
				},
				activityList: [],
				schoolList: ["屏峰校区", "朝晖校区", "莫干山校区"],
				derictionList: [],
				activityAddress: [],
				recruitId: "",
				pickerOptionsAc: {},
				editorOption: {
					placeholder: "请输入内容......",
				}
			};
		},
		mounted() {
			this.recruitId = this.$route.query.recruitId
			this.activityListFn()
			this.getRecruitFacultyList()
			this.getRecruitDetail()
		},
		methods: {
			hiddenNow() {
				this.$nextTick(() => {
					document
						.getElementsByClassName('el-button--text')[0]
						.setAttribute('style', 'display:none')
				})
			},
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				}
			},
			activityListFn() {
				let params = {
					pageIndex: 1,
					pageSize: 999,
					status: "PROCESSING",
				};
				getActivityList(params).then(res => {
					if (res.code == 0) {
						this.activityList = res.data.map(item => {
							return {
								label: item.activityType == 1 ? item.activityName : `${item.comBaseName} | ` + item
									.activityName,
								value: item.id
							}
						})
					}
				});
			},
			getRecruitFacultyList() {
				let params = {
					pageIndex: 1,
					pageSize: 999,
				};
				recruitFacultyList(params).then((res) => {
					this.derictionList = res.data
					this.derictionList.unshift({
						id: "All",
						name: "全部"
					})
				})
			},
			timeHandle(type, index) {
				switch (type) {
					case "add":
						this.recruitForm.recruitTimes.push({
							starTime: "",
							endTime: "",
						})
						break;
					case "cut":
						this.recruitForm.recruitTimes.splice(index, 1)
						break;
				}
			},
			activityChange(e) {
				console.log("activityChange", e)
				this.recruitForm.activityName = this.activityList.filter(item => item.value == e)[0].label
				this.activityDetailFn('new')
			},
			// 获取活动详情
			activityDetailFn(type) {
				this.pickerOptionsAc = {}
				getActivityDetail(this.recruitForm.activityId, {
					id: this.recruitForm.activityId
				}).then((res) => {
					if (res.code == 0) {
						if (type == 'new') {
							this.recruitForm.recruitTimes = [{
								starTime: "",
								endTime: ""
							}]
							this.recruitForm.recruitAddress = res.data.address
						}
						let startDate = res.data.starTime
						let endDate = res.data.endTime
						this.pickerOptionsAc = {
							disabledDate: (time) => {
								if (startDate && endDate) {
									// let timeZone = 182 * 24 * 3600 * 1000; //6个月
									let oneTimer = 1 * 24 * 3600 * 1000
									let startTimer = new Date(startDate).getTime()
									let endTimer = new Date(endDate).getTime()
									let limitDate =
										time.getTime() < startTimer ||
										time.getTime() > endTimer
									return limitDate;
								} else {
									return ''
								}
							}
						}
					}
				})
			},
			// 获取招募详情
			getRecruitDetail() {
				detailRecruit(this.recruitId, {
					id: this.recruitId
				}).then(res => {
					if (res.code == 0) {
						this.recruitForm = res.data
						this.activityDetailFn()
						if (this.recruitForm.ccampus) {
							this.recruitForm.ccampus = this.recruitForm.ccampus.split(",")
						} else {
							this.recruitForm.ccampus = ["屏峰校区"]
						}
						if (this.recruitForm.orientationId) {
							this.recruitForm.orientationId = this.recruitForm.orientationId.split(",")
						} else {
							this.recruitForm.orientationId = ["All"]
						}
					}
				})
			},
			editorFocus(e) {
				// e.enable(false);
			},
			tabSelect(type, item) {
				switch (type) {
					case "school":
						// this.recruitForm.ccampus = item
						let selectIndex = this.recruitForm.ccampus.indexOf(item)
						if (selectIndex == -1) {
							this.recruitForm.ccampus.push(item)
						} else {
							this.recruitForm.ccampus.splice(selectIndex, 1)
						}
						break;
					case "deriction":
						// let index = this.recruitForm.orientationId.indexOf(item.id)
						// if (index == -1) {
						// 	this.recruitForm.orientationId.push(item.id)
						// } else {
						// 	this.recruitForm.orientationId.splice(index, 1)
						// }
						if (item.id != 'All') {
							let selectIndex = this.recruitForm.orientationId.indexOf(item.id)
							if (selectIndex == -1) {
								this.recruitForm.orientationId.push(item.id)
							} else {
								this.recruitForm.orientationId.splice(selectIndex, 1)
							}
						}
						if (this.recruitForm.orientationId.length == 0) {
							this.recruitForm.orientationId = ["All"]
						} else {
							let allIndex = this.recruitForm.orientationId.indexOf("All")
							if (allIndex != -1) {
								this.recruitForm.orientationId.splice(allIndex, 1)
							}
						}
						break;
				}
			},
			submitRecruit() {
				let recruitData = JSON.parse(JSON.stringify(this.recruitForm))
				if (!recruitData.activityId) {
					this.$message.error("请选择活动名称~")
					return
				}
				let recruitIndex = recruitData.activityName.indexOf("|")
				console.log("recruitIndex", recruitIndex)
				if (recruitIndex > -1) {
					recruitData.activityName = recruitData.activityName.substring(recruitIndex + 2, recruitData
						.activityName.length)
				}
				if (!recruitData.recruitName) {
					this.$message.error("请输入招募名称~")
					return
				}
				if (recruitData.ccampus.length == 0) {
					this.$message.error("请选择招募小区~")
					return
				} else {
					recruitData.ccampus = recruitData.ccampus.join(",")
				}
				if (!recruitData.peopleNum) {
					this.$message.error("请输入需求人数~")
					return
				}
				if (!recruitData.activityDetails) {
					this.$message.error("请输入活动详情~")
					return
				}
				// if (!recruitData.timingRecruitTime) {
				// 	this.$message.error("请选择定时招募时间~")
				// 	return
				// }
				if (!recruitData.contact) {
					this.$message.error("请输入联系人~")
					return
				}
				if (recruitData.orientationId && recruitData.orientationId.length > 0) {
					if (recruitData.orientationId[0] == 'All') {
						recruitData.orientationId = ""
					} else {
						recruitData.orientationId = recruitData.orientationId.join(",")
					}
				}
				console.log("发布招募", recruitData)
				// if (recruitData.orientationId && recruitData.orientationId.length > 0) {
				// 	recruitData.orientationId = recruitData.orientationId.join(",")
				// }
				editRecruit(recruitData.id, recruitData).then((res) => {
					if (res.code == 0) {
						this.$message.success("修改招募成功~")
						this.$router.go(-1)
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px !important;
		line-height: 36px !important;
		border-radius: 0px 2px 2px 0px;
		border: 1px solid #EDF1F7;
		border-left: 0;
	}

	/deep/.el-textarea__inner {
		resize: none;
		border: 1px solid #EDF1F7;
		border-radius: 0px 0px 2px 2px;
		height: 320px;
	}

	.recruitAdd {
		padding: 20px 20px;
		display: flex;
		flex-direction: column;

		.common-row {
			margin-bottom: 16px;
			display: flex;
			flex-direction: column;

			.common-row-content {
				flex: 1;
				display: flex;
				align-items: center;
			}

			.common-row-icon {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 120px;
				height: 36px;
				border-radius: 0px 2px 2px 0px;
				border: 1px solid #EDF1F7;
				cursor: pointer;
				font-size: 14px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #606266;
				margin-left: 16px;

				img {
					width: 16px;
					height: 16px;
					margin-right: 8px;
				}
			}

			.common-row-tip {
				width: 100%;
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 17px;
				color: #909399;
				text-align: left;
				margin: 8px 0 5px 0;
			}

			.common-row-ipt {
				width: 260px;
				height: 36px;
				line-height: 36px;
			}

			.common-row-select {
				flex: 1;
			}

			.common-row-date {
				flex: 1;
			}

			.common-row-tablist {
				flex: 1;
				margin-left: 10px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.common-row-tabitem {
					min-width: 120px;
					height: 36px;
					line-height: 36px;
					background: #FFFFFF;
					border: 1px solid #EDF1F7;
					border-radius: 2px;
					text-align: center;
					font-size: 14px;
					font-family: PingFang SC;
					font-weight: 400;
					color: #303133;
					cursor: pointer;
					box-sizing: border-box;
					margin: 0 10px 8px 0;

					&.tabitem_active {
						background: rgba(28, 171, 185, 0.1);
						border: 1px solid $theme_color;
						color: $theme_color;
					}
				}
			}
		}

		.recruitAdd-desc {
			width: 100%;
			display: flex;
			flex-direction: column;
			margin-bottom: 16px;

			.recruitAdd-desc-label {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 36px;
				color: #606266;
				border-radius: 2px 2px 0px 0px;
				background: #F6F7F9;
				border: 1px solid #EDF1F7;
				padding: 0 20px;
			}

			.recruitAdd-desc-ipt {}
		}

		.recruitAdd-submit {
			display: flex;

			.recruitAdd-submit-btn {
				margin-left: auto;
			}
		}
	}
</style>
